<template>
    <div class="ProgrammesAndWorkshop">
        <div class="container">
            <div class="pnw-title flex flex-col justify-center items-center py-10">
                <h1 class="bold text-center font-bebas leading-none">Programmes & Workshop</h1>
            </div>
        </div>
        <div class="image-wrapper">
            <!-- <img src="../assets/programmes-ori.png" alt class="image" /> -->
        </div>

        <div class="container rounded-md programmes-content grid grid-cols-2 grid-flow-row gap-4">
            <div class="programmes-content-box p-6 m-10">
                <h1
                    class="text-4xl bold orange-text capitalize font-bebas leading-none"
                >Junior Cooking Academy</h1>
                <div class="rectangle-orange mt-6"></div>
                <p
                    class="my-6 text-base"
                >Cooking and baking are a great opportunity to sharpen your children's life skills and sparks creativity. The programme is designed to teach children the knowledge and relationship between food and science.</p>
                <!-- <h1 class="text-3xl bold orange-text capitalize font-bebas leading-none">Timing</h1>
                <p class="mt-4 font-bold text-base">
                    PITH Nursery 1 & 2, Kindergarten 1 & 2
                </p>
                <p class="text-base">
                    8.15am to 1.00pm
                </p> -->
            </div>
            <div class="programmes-content-box p-6 m-10">
                <h1
                    class="text-4xl bold orange-text capitalize font-bebas leading-none"
                >Art and Craft</h1>
                <div class="rectangle-orange mt-6"></div>
                <p
                    class="my-6 text-base"
                >Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum assumenda voluptatem corporis exercitationem autem accusamus tempore commodi ipsum eum, dolores praesentium blanditiis temporibus, porro beatae similique repellendus natus iure quas!</p>
            </div>
            <div class="programmes-content-box p-6 m-10">
                <h1
                    class="text-4xl bold orange-text capitalize font-bebas leading-none"
                >Junior Science Explorer</h1>
                <div class="rectangle-orange mt-6"></div>
                <p
                    class="my-6 text-base"
                >Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum assumenda voluptatem corporis exercitationem autem accusamus tempore commodi ipsum eum, dolores praesentium blanditiis temporibus, porro beatae similique repellendus natus iure quas!</p>
            </div>
            <div class="programmes-content-box p-6 m-10">
                <h1
                    class="text-4xl bold orange-text capitalize font-bebas leading-none"
                >Stretch & Fitness</h1>
                <div class="rectangle-orange mt-6"></div>
                <p
                    class="my-6 text-base"
                >Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum assumenda voluptatem corporis exercitationem autem accusamus tempore commodi ipsum eum, dolores praesentium blanditiis temporibus, porro beatae similique repellendus natus iure quas!</p>
            </div>
            <div class="programmes-content-box p-6 m-10 flex-shrink flex-grow">
                <h1
                    class="text-4xl bold orange-text capitalize font-bebas leading-none"
                >Speech & Drama</h1>
                <div class="rectangle-orange mt-6"></div>
                <p
                    class="my-6 text-base"
                >Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum assumenda voluptatem corporis exercitationem autem accusamus tempore commodi ipsum eum, dolores praesentium blanditiis temporibus, porro beatae similique repellendus natus iure quas!</p>
            </div>
            <div class="programmes-content-box p-6 m-10 flex-shrink flex-grow">
                <h1
                    class="text-4xl bold orange-text capitalize font-bebas leading-none"
                >Mandarin Language</h1>
                <div class="rectangle-orange mt-6"></div>
                <p
                    class="my-6 text-base"
                >Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum assumenda voluptatem corporis exercitationem autem accusamus tempore commodi ipsum eum, dolores praesentium blanditiis temporibus, porro beatae similique repellendus natus iure quas!</p>
            </div>
            <div class="programmes-content-box p-6 m-10 flex-shrink flex-grow">
                <h1
                    class="text-4xl bold orange-text capitalize font-bebas leading-none"
                >English Language</h1>
                <div class="rectangle-orange mt-6"></div>
                <p
                    class="my-6 text-base"
                >Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum assumenda voluptatem corporis exercitationem autem accusamus tempore commodi ipsum eum, dolores praesentium blanditiis temporibus, porro beatae similique repellendus natus iure quas!</p>
            </div>
            <div class="programmes-content-box p-6 m-10">
                <h1
                    class="text-4xl bold orange-text capitalize font-bebas leading-none"
                >Bahasa Malaysia</h1>
                <div class="rectangle-orange mt-6"></div>
                <p
                    class="my-6 text-base"
                >Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum assumenda voluptatem corporis exercitationem autem accusamus tempore commodi ipsum eum, dolores praesentium blanditiis temporibus, porro beatae similique repellendus natus iure quas!</p>
            </div>
        </div>
        <div class="contact-us text-center py-10">
            <h1 class="text-4xl capitalize mb-4 font-bebas">Want to know more?</h1>
            <router-link class="font-bold my-6" to="/#contact-us">
                <button class="button uppercase text-base">Contact Us</button>
            </router-link>
        </div>
    </div>
</template>


<script>
export default {
    name: "ProgrammesAndWorkshop",
    components: {},
};
</script>


<style scoped lang="less">
.ProgrammesAndWorkshop {
    background-color: #ededed;
}
.image-wrapper {
    width: 100%;
    height: 360px;
    background-image: url("../assets/programmes.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}
.pnw-title {
    h1 {
        font-size: 54px;
    }
}
.programmes-content-box {
    background-color: white;
}
.orange-text {
    color: #de6a07;
}
.contact-us {
    background-color: white;
}
.button {
    background-color: #de6a07;
    border: none;
    color: white;
    padding: 8px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    @apply my-6;
    cursor: pointer;
}
@media (min-width: 280px) and (max-width: 480px) {
    .pnw-title {
        h1 {
            @apply text-3xl;
        }
    }
    .programmes-content-box {
        @apply p-5 m-4;
    }
    .programmes-content {
        @apply grid-cols-1;
    }
    .image-wrapper {
        background-position: 74% 50%;
    }
}

@media (min-device-width: 481px) and (max-device-width: 767px) {
    .programmes-content {
        @apply grid-cols-1;
    }
    .programmes-content-box {
        @apply m-5;
    }
    .image-wrapper {
        background-position: 74% 50%;
    }
}
@media (min-device-width: 768px) and (max-device-width: 1024px) {
    .programmes-content-box {
        @apply m-5;
    }
    .image-wrapper {
        background-position: 74% 50%;
    }
}
</style>
